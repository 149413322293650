import React from 'react';
import styled from 'styled-components';
import { maxMobile, midWidthApp } from 'Utils/utilsStyle';

type Props = {
  personaColor?: string,
  diameter?: string,
  diameterXsm?: string,
  diameterXxsm?: string,
  margin?: string,
  marginSm?: string,
  innerSpace?: string,
  personImage: string,
} & typeof defaultProps;

const defaultProps = {
  personaColor: '',
  diameter: '18px',
  diameterXsm: '16px',
  diameterXxsm: '14px',
  margin: '0px 8px',
  marginSm: '0px 6px',
  innerSpace: '1px',
};

const Wrapper = styled.div < { color: string, diameter: string, diameterXxsm: string, diameterXsm: string, margin: string, marginSm: string, innerSpace: string }>`
  &.image-persona {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: ${(props) => props.diameter};
    height: ${(props) => props.diameter};
    background: conic-gradient(from -180deg, ${(props) => props.color});
    border-radius: 50%;
    padding: ${(props) => props.innerSpace};
    margin: ${(props) => props.margin};
    @media all and (max-width: ${midWidthApp}) {
      width: ${(props) => props.diameterXsm};
      height: ${(props) => props.diameterXsm};
    }
    @media all and (max-width: ${maxMobile}) {
      width: ${(props) => props.diameterXxsm};
      height: ${(props) => props.diameterXxsm};
      margin: ${(props) => props.marginSm};
    }
    .history-img {
      width: inherit;
      height: inherit;
      border-radius: 50%;
      padding: ${(props) => props.innerSpace};
    }
  }
`;

const PeopleWidgetSmall = function PeopleWidgetSmall(props: Props) {
  const {
    personaColor, personImage, diameter, margin, innerSpace, diameterXxsm, diameterXsm,
    marginSm,
  } = props;

  return (
    <Wrapper
      className="image-persona"
      color={personaColor}
      diameter={diameter}
      diameterXsm={diameterXsm}
      diameterXxsm={diameterXxsm}
      margin={margin}
      marginSm={marginSm}
      innerSpace={innerSpace}
    >
      <img src={personImage} alt="history-img" className="history-img" />
    </Wrapper>
  );
};

PeopleWidgetSmall.defaultProps = defaultProps;
export default PeopleWidgetSmall;
