import {
  primaryHeadingColor, primaryFont, wMedium,
  scrollbar, midWidthApp,
  maxWidth,
  retina,
  maxMobile,
  primaryColor,
} from 'Utils/utilsStyle';
import React, { useState } from 'react';
import styled from 'styled-components';
import AlertsSearch from 'Components/alerts/components/AlertsSearch';
import { Checkbox } from 'antd';
import { useSelector } from 'react-redux';
import { selectCustomCategories } from 'Selector/ChatBotV2Selector';
import EmptyCategory from './EmptyCategory';

const Wrapper = styled.div`
.buttons-div-popover {
  padding: 16px 16px 0px;
  @media all and (max-width: ${midWidthApp}) {
    padding: 14px 14px 0px;
  }
  @media all and (max-width: ${maxMobile}) {
    padding: 10px 10px 0px;
  }
  .search-wrapper {
    margin-top: 8px;
    @media all and (max-width: ${midWidthApp}) {
      height: 30px;
    }
    @media all and (max-width: ${maxMobile}) {
      margin-top: 6px;
      height: 25px;
    }
    .ant-input-affix-wrapper {
      height: 34px;
      border: 0.8px solid #DADADA;
      padding: 6.5px 30px 6.5px 11px;
      @media all and (max-width: ${midWidthApp}) {
        height: 30px;
      }
      @media all and (max-width: ${maxMobile}) {
        height: 25px;
        padding: 4.5px 30px 3.5px 7px;
      }
      .ant-input-prefix {
        @media all and (max-width: ${maxWidth}), ${retina} {
          padding: 0px 5px 0px 8px;
        }
        @media all and (max-width: ${maxMobile}) {
          border-right: 1px solid #DADADA;
        }
        .search-global-icon-svg {
          width: 12px;
          height: 12px;
          path {
            fill: ${primaryHeadingColor};
            opacity: .5;
          }
        }
      }
      .ant-input::placeholder {
        color: #C1C1C1;
        font-size: 16px;
        font-style: italic;
        font-weight: 400 !important;
        line-height: 18px;
        letter-spacing: -0.45px;
        @media all and (max-width: ${maxWidth}), ${retina} {
          font-size: 14px;
        }
        @media all and (max-width: ${midWidthApp}) {
          font-size: 12px;
        }
        @media all and (max-width: ${maxMobile}) {
          font-size: 10px;
        }
      }
      .ant-input {
        font-size: 16px;
        @media all and (max-width: ${maxWidth}), ${retina} {
          font-size: 14px;
        }
        @media all and (max-width: ${midWidthApp}) {
          font-size: 12px;
        }
        @media all and (max-width: ${maxMobile}) {
          font-size: 10px;
        }
      }
    }
    .cross-icon  {
      cursor: pointer;
      top: 9px;
      right: 8px;
      @media all and (max-width: ${midWidthApp}) {
        top: 7px;
      }
      @media all and (max-width: ${maxMobile}) {
        top: 7px;
        width: 10px;
        height: 10px;
      }
    }
  }
}
.popover-content {
  &.empty-content {
    padding: 12px 16px 0px 16px;
  }
  padding: 12px 4px 0px 16px;
  .ant-divider {
    &.ant-divider-horizontal {
      margin: 12px 0px 12px;
      border: -0.4px solid #D8D8D8;
    }
  }
  .scroll-wrapper {
    max-height: 165px;
    height: 165px;
    ${scrollbar};
    border-radius: 0px !important;
    @media all and (max-width: ${maxWidth}), ${retina} {
      max-height: 139px;
      height: 139px;
    }
    @media all and (max-width: ${maxMobile}) {
      max-height: 110px;
      height: 110px;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
    }
    .category-option {
      display: flex;
      align-items: center;
      @media all and (max-width: ${maxMobile}) {
        height: 20px;
      }
      .ant-checkbox-wrapper {
        width: unset;
        margin-bottom: 0px;
        .ant-checkbox {
          &.ant-checkbox-checked {
            .ant-checkbox-inner {
              border: 2px solid ${primaryColor};
              @media all and (max-width: ${maxMobile}) {
                border: 1px solid ${primaryColor};
              }
              &::after {
                transform: rotate(45deg) scale(0.9) translate(-50%, -43%);
                top: 44%;
              }
            }
            &::after {
              border: 0px;
              border-radius: 2px;
            }
          }
          .ant-checkbox-inner {
            @media all and (max-width: ${maxWidth}), ${retina} {
              width: 18px;
              height: 18px;
              border-radius: 4px;
            }
            @media all and (max-width: ${midWidthApp}) {
              width: 16px;
              height: 16px;
            }
            @media all and (max-width: ${maxMobile}) {
              width: 14px;
              height: 14px;
              border-radius: 3px;
              border-width: 1px;
            }
          }
        }
      }
      .option-text {
        color: #7E7E7E;
        font-family: ${primaryFont};
        font-size: 16px;
        font-weight: ${wMedium};
        line-height: 19px;
        letter-spacing: -0.4px;
        margin-left: 8px;
        @media all and (max-width: ${maxWidth}), ${retina} {
          font-size: 14px;
          margin-left: 6px;
        }
        @media all and (max-width: ${midWidthApp}) {
          font-size: 12px;
        }
        @media all and (max-width: ${maxMobile}) {
          font-size: 10px;
        }
      }
    }
  }
}
`;

type Props = {
  selectedCategoryIds: number[]
  setIsNewCategory: (value: boolean) => void
  setSelectedCategoryIds: (value: number[]) => void
};

const ExistingCategoryTab = function ExistingCategoryTab(props: Props) {
  const { selectedCategoryIds, setIsNewCategory, setSelectedCategoryIds } = props;
  const customCategories = useSelector(selectCustomCategories);
  const [filteredCategories, setFilteredCategories] = useState(customCategories);

  const handleCheckbox = (isChecked: boolean, id: number) => {
    if (isChecked) {
      setSelectedCategoryIds([...selectedCategoryIds, id]);
    } else {
      setSelectedCategoryIds(selectedCategoryIds?.filter((c) => c !== id));
    }
  };

  const handleSearch = (searchText: string) => {
    const lowercasedSearchTerm = searchText.toLowerCase();
    setFilteredCategories(customCategories.filter(
      (item) => item.title.toLowerCase().includes(lowercasedSearchTerm),
    ));
  };

  return (
    <Wrapper className="existing-category-tab-wrapper">
      <div className="buttons-div-popover">
        <span
          tabIndex={0}
          role="button"
          className="button-new"
          onClick={() => setIsNewCategory(true)}
          onKeyPress={() => setIsNewCategory(true)}
        >
          Create New Category
        </span>
        <AlertsSearch
          onSearching={handleSearch}
          placeholderText="Search your Category"
          margin="0px"
        />
      </div>
      {filteredCategories?.length === 0 ? (
        <div className="popover-content empty-content">
          <EmptyCategory />
        </div>
      ) : (
        <div className="popover-content">
          <div className="scroll-wrapper">
            <h3 className="heading-text">Your Categories</h3>
            {filteredCategories.map((category) => (
              <span className="category-option">
                <Checkbox
                  checked={selectedCategoryIds.includes(category?.categoryId || 0)}
                  onChange={(e) => handleCheckbox(e.target.checked, category?.categoryId || 0)}
                />
                <span className="option-text">{category?.title}</span>
              </span>
            ))}
          </div>
        </div>
      )}
    </Wrapper>
  );
};
export default ExistingCategoryTab;
