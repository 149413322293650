import { Popover } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  maxMobile,
  maxWidth,
  midWidthApp,
  primaryColor, primaryFont, retina, wMedium,
} from 'Utils/utilsStyle';
import ExistingCategoryTab from 'Components/chatBotV2/mainComponents/chatbotLibrary/component/ExistingCategoryTab';
import CreateNewCategoryTab from 'Components/chatBotV2/mainComponents/chatbotLibrary/component/CreateNewCategoryTab';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'Slice/ChatbotV2Slice';
import { selectSelectedPromptCateogry } from 'Selector/ChatBotV2Selector';
import { replaceTags } from 'Utils/UtilityFunctions';
import { PromptCategoryV2 } from 'Types/ChatBotV2Types';
import { TooltipPlacement } from 'antd/lib/tooltip';
import PlusIconSvg from '../assets/svgIcons/PlusIconSvg';
import IconTooltipGlobal from './IconTooltipGlobal';
import Edit from '../assets/svgIcons/Edit';
import GilroyDeleteChatPopup from '../modal/GilroyDeleteChatPopup';

const ContentWrapper = styled.div`
border-radius: 8px;
background: #F9F8F6;
box-shadow: 0px 0px 20px -2px rgba(0, 0, 0, 0.28);
max-width: 267px;
@media all and (max-width: ${maxMobile}) {
  max-width: 203px;
  min-width: 203px;
}
.button-new {
  cursor: pointer;
  border-radius: 22px;
  border: 0.8px solid ${primaryColor};
  background: ${primaryColor};
  color: #fff;
  font-family: ${primaryFont};
  font-size: 16px;
  font-weight: ${wMedium};
  line-height: 28px;
  letter-spacing: -0.4px;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 2px;
  @media all and (max-width: ${maxWidth}), ${retina} {
    font-size: 14px;
  }
  @media all and (max-width: ${midWidthApp}) {
    font-size: 12px;
    padding: 0px;
  }
  @media all and (max-width: ${maxMobile}) {
    font-size: 10px;
    line-height: normal;
    height: 20px;
    padding: 3px;
  }
  &.footer-btn {
    font-size: 14px;
    min-width: 78px;
    height: 24px;
    line-height: normal;
    @media all and (max-width: ${midWidthApp}) {
      font-size: 12px;
      height: 21px;
      min-width: 69px;
      line-height: 18px;
    }
    @media all and (max-width: ${maxMobile}) {
      font-size: 10px;
      min-width: 58px;
      height: 18px;
      line-height: normal;
      padding: 1px;
    }
    &.cancel {
      background-color: #fff;
      color: ${primaryColor};
      margin-right: 8px;
    }
  }
}
.footer-div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 52px;
  border-top: 0.7px solid #DDD;
  background: #fff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  @media all and (max-width: ${midWidthApp}) {
    padding: 11px 46px 10px;
  }
  @media all and (max-width: ${maxMobile}) {
    padding: 9px 25px;
  }
}
`;
type Props = {
  promptId?: number;
  editCategory?: boolean,
  chatId?: number,
  prompt?: string,
  customClassName?: string,
  promptCategories?: PromptCategoryV2[]
  popoverPlacement: TooltipPlacement,
} & typeof defaultProps;

const defaultProps = {
  editCategory: false,
  customClassName: '',
  promptId: 0,
  chatId: 0,
  prompt: '',
  promptCategories: [] as PromptCategoryV2[],
};

const CategoryPopover = function CategoryPopover(props: Props) {
  const {
    editCategory, customClassName, promptId, chatId, prompt, promptCategories,
    popoverPlacement,
  } = props;
  const [isNewCategory, setIsNewCategory] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const promptCategoryIds = promptCategories?.map((category) => category?.categoryId as number);
  const [selectedCategoryIds, setSelectedCategoryIds] = useState<number[]>(promptCategoryIds);
  const selectedCategory = useSelector(selectSelectedPromptCateogry);
  const { categoryId, title: selectedTitle, description: selectedDesciption } = selectedCategory;
  const [title, setTitle] = useState(editCategory ? selectedTitle : '');
  const [description, setDescription] = useState(editCategory ? selectedDesciption : '');
  const dispatch = useDispatch();

  const handleAddRemovePrompt = () => {
    dispatch(actions.addRemovePromptFromCategory({
      newCategory: isNewCategory,
      ...(promptId ? { promptId } : { chatId, prompt: replaceTags(prompt || '') }),
      ...(title?.length ? { categoryName: title } : {}),
      ...(description?.length ? { categoryDescription: description } : {}),
      categoryIds: selectedCategoryIds?.toString(),
    }));
    setIsNewCategory(false);
    setTitle('');
    setDescription('');
  };

  const handleSave = () => {
    if (editCategory) {
      dispatch(actions.editCustomCategory({
        categoryId: categoryId || 0,
        categoryName: title,
        categoryDescription: description,
      }));
      setTitle('');
      setDescription('');
    } else if (!selectedCategoryIds?.length && !isNewCategory) {
      setShowDeletePopup(true);
      return;
    } else {
      handleAddRemovePrompt();
    }
    setIsPopoverOpen(false);
  };

  const handleCancel = () => {
    if (isNewCategory) {
      setIsNewCategory(false);
    } else {
      setIsPopoverOpen(false);
    }
  };

  const areListsEqual = (list1: number[], list2: number[]): boolean => {
    if (list1.length !== list2.length) return false;

    const sortedList1 = [...list1].sort((a, b) => a - b);
    const sortedList2 = [...list2].sort((a, b) => a - b);

    return sortedList1.every((value, index) => value === sortedList2[index]);
  };

  const isDisabled = () => {
    if (isNewCategory && !title?.trim()?.length) {
      return true;
    }

    if (editCategory && (!title?.trim()?.length
      || (title?.trim() === selectedTitle?.trim()
        && description?.trim() === selectedDesciption?.trim()))) {
      return true;
    }

    if (!isNewCategory && !editCategory) {
      if (promptCategoryIds?.length === selectedCategoryIds?.length
        || areListsEqual(promptCategoryIds, selectedCategoryIds)) {
        return true;
      }
    }
    return false;
  };

  const content = (
    <ContentWrapper onClick={(e) => e.stopPropagation()}>
      {isNewCategory || editCategory ? (
        <CreateNewCategoryTab
          categoryId={selectedCategory?.categoryId}
          isEdit={editCategory}
          title={title}
          description={description}
          setTitle={setTitle}
          setDescription={setDescription}
        />
      ) : (
        <ExistingCategoryTab
          selectedCategoryIds={selectedCategoryIds}
          setSelectedCategoryIds={setSelectedCategoryIds}
          setIsNewCategory={setIsNewCategory}
        />
      )}
      <div className="footer-div">
        <span
          tabIndex={0}
          role="button"
          className="button-new footer-btn cancel"
          onClick={handleCancel}
          onKeyPress={handleCancel}
        >
          {isNewCategory ? 'Back' : 'Cancel'}
        </span>
        <span
          tabIndex={0}
          role="button"
          className={`button-new footer-btn ${isDisabled() ? 'disabled' : ''}`}
          onClick={handleSave}
          onKeyPress={handleSave}
        >
          {editCategory ? 'Update' : 'Save'}
        </span>
      </div>
    </ContentWrapper>
  );
  useEffect(() => {
    const node = document.querySelector('.infinite-scroll-gilroy-chat');
    return isPopoverOpen ? node?.classList.add('is-category-popover-open') : node?.classList.remove('is-category-popover-open');
  }, [isPopoverOpen]);
  return (
    <>
      <Popover
        open={isPopoverOpen}
        onOpenChange={setIsPopoverOpen}
        placement={popoverPlacement}
        content={content}
        trigger="click"
        getPopupContainer={(e: HTMLElement) => e}
        overlayClassName={classNames(`prompt-custom-popover ${customClassName}`, { editPromptPopover: editCategory })}
        className="prompt-category-popover categoryPopover"
      >
        <span>
          <IconTooltipGlobal
            tooltipPlacement="bottom"
            tooltipTitle={editCategory ? 'Edit Category' : 'Add Category'}
            icon={editCategory ? <Edit fllColor={isPopoverOpen ? primaryColor : '#667982'} onClick={() => { }} /> : <PlusIconSvg fillColor={isPopoverOpen ? primaryColor : '#667982'} className="plus-icon-svg" onClick={() => setIsPopoverOpen(!isPopoverOpen)} />}
          />
        </span>
      </Popover>
      <GilroyDeleteChatPopup
        deleteBtnText="Delete"
        isVisible={showDeletePopup}
        setIsVisible={setShowDeletePopup}
        popupText="Are you sure you want to delete this prompt from all selected categories?"
        onDelete={handleAddRemovePrompt}
      />
    </>
  );
};
CategoryPopover.defaultProps = defaultProps;
export default CategoryPopover;
