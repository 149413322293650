/* eslint-disable jsx-a11y/click-events-have-key-events */
import XiqGptEmailGilroySvg from 'Components/common/assets/svgIcons/xiqGptEmailGilroySvg';
import HTMLReactParser from 'html-react-parser';
import React, { useState, useEffect } from 'react';
import ContentEditable from 'react-contenteditable';
import styled from 'styled-components';
import {
  greyColor, maxTablet, maxWidth, primaryColor, primaryFont,
  retina, wLight, wMedium,
} from 'Utils/utilsStyle';
import classNames from 'classnames';
import Tick from 'Components/common/assets/svgIcons/Tick';
import Cross from 'Components/common/assets/svgIcons/Cross';
import { useDispatch, useSelector } from 'react-redux';
import { actions as UiAction } from 'Slice/UISlice';
import GilroyDeleteChatPopup from 'Components/common/modal/GilroyDeleteChatPopup';
import GilroyFeedbackChatPopup from 'Components/common/modal/GilroyFeedbackChatPopup';
import { ChatV2 } from 'Types/ChatBotV2Types';
import { actions } from 'Slice/ChatbotV2Slice';
import {
  selectChatHistory, selectChatLoading, selectEditChatLoading, selectEditInProgressId,
} from 'Selector/ChatBotV2Selector';
import {
  getCurrentTime, removeHtmlTags, scrollToElement, valueToObject,
} from 'Utils/UtilityFunctions';
import { usePrevious } from 'Utils/hooks/usePrevious';
import { actions as flurryActions } from 'Slice/HelperSlice';
import { translateSkipAreas } from 'Utils/Constants';
import { selectGilroyDiscalimer } from 'Selector/GlobalSearchContentSelector';
import OutputActionsDiv from './OutputActionsDiv';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 8px 0;
  padding: 0px 0px 25px 5px;
  @media all and (max-width: ${maxTablet}) {
    padding: 0px 0px 25px 8px;
  }
  .left-side {
    display: flex;
    flex-direction: column;
    align-items: center;
    .gillRoy {
      width: 57px;
      min-width: 57px;
      height: 52px;
      @media all and (max-width: ${maxWidth}), ${retina} {
        width: 55px;
        min-width: 55px;
        height: 50px;
      }
      @media all and (max-width: ${maxTablet}) {
        width: 33px;
        min-width: 33px;
        height: 33px;
      }
    }
    .edited-tag {
      width: fit-content;
      padding: 2px 3px;
      background: #fff;
      box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.21);      
      border-radius: 2px;
      font-family: ${primaryFont};
      font-size: 12px;
      text-align: center;
      font-weight: ${wMedium};
      letter-spacing: -0.43px;
      color: ${greyColor};
      margin-top: 8px;
      @media all and (max-width: ${maxTablet}) {
        font-size: 10px;
        margin-top: 3px;
      }
    }
  }
  .right-side {
    @media all and (max-width: ${maxWidth}), ${retina} {
      width: calc(100% - 55px);
      padding-left: 4px;
    }
    width: calc(100% - 57px);
    margin-top: 13px;
    padding-left: 8px;
    padding-right: 20px;
    @media all and (max-width: ${maxTablet}) {
      width: calc(100% - 33px);
      margin-top: 8px;
    }
    .queryTitle {
      font-weight: ${wLight};
      font-family: ${primaryFont};
      font-size: 16px;
      line-height: 24px;
      white-space: break-spaces;
      word-wrap: break-word;
      color: #0D0D0D;
      @media all and (max-width: ${maxTablet}) {
        font-size: 12px;
        line-height: 12px;
      }
    }
     .output-content-editTable {
      border-radius: 6px;
      border: 1.6px solid ${primaryColor};
      padding: 8px 7px;
      outline: none;
      &:focus-visible {
        border-radius: 6px;
        border: 1.6px solid ${primaryColor};
        outline-color: ${primaryColor};
      }
  }
  }
  .output-actions-div {
    position: absolute;
    bottom: 0px;
    left: calc(0% + 62px);
    @media all and (max-width: ${maxWidth}), ${retina} {
      left: calc(0% + 56px);
      bottom: -5px;
    }
    @media all and (max-width: ${maxTablet}) {
      left: calc(0% + 37px);
    }
  }
  .edit-action-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 7px;
    .cross {
      cursor: pointer;
      svg {
        width: 13px;
        height: 13px;
        @media all and (max-width: ${maxTablet}) {
          width: 10px;
          height: 10px;
        }
      }
      &.disabled{
        pointer-events: none;
        opacity: 0.6;
      }
    }
    .tick {
      cursor: pointer;
      svg {
        width: 17px;
        height: 17px;
        @media all and (max-width: ${maxTablet}) {
          width: 12px;
          height: 12px;
        }
      }
      &.disabled{
        pointer-events: none;
        opacity: 0.3;
      }
    }
  }
`;

type Props = {
  chatObject: ChatV2
  selectedConvoId: number
  onEditClickFn: (a: number) => void
  onRegenerate: () => void
};

const GPTGeneratedOutput = function GPTGeneratedOutput(props: Props) {
  const host = window.location.href;
  const dispatch = useDispatch();
  const {
    chatObject, selectedConvoId, onEditClickFn, onRegenerate,
  } = props;
  const [chatResponse, setChatResponse] = useState('');
  const [showEditResponse, setShowEditResponse] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showFeedbackPopup, setShowFeedbackPopup] = useState(false);
  const [isFeedbackPositive, setIsFeedBackPositive] = useState(false);
  const [disableTick, setDisableTick] = useState(false);
  const [enterPressed, setEnterPressed] = useState(false);

  const editChatLoading = useSelector(selectEditChatLoading);
  const chatLoading = useSelector(selectChatLoading);
  const editInProgressId = useSelector(selectEditInProgressId);
  const chatHistory = useSelector(selectChatHistory);
  const startTime = getCurrentTime();
  const prevEditChatLoading = usePrevious(editChatLoading);
  const { showDisclaimer } = useSelector(selectGilroyDiscalimer);
  const [outputDivActionVisibility, setOutputDivActionVisibility] = useState(false);
  const {
    chat, id, edited, responseId,
  } = chatObject;

  useEffect(() => {
    setChatResponse(chat.replace(/\n/g, '<br>').replace(/\r/g, ''));
  }, [chat]);

  const onCross = () => {
    const node = document.querySelector('#scrollWrapperChat') as HTMLDivElement;
    dispatch(UiAction.isEditChatOpenInput(false));
    setShowEditResponse(false);
    setTimeout(() => {
      scrollToElement(`output-js-${id}`, node);
      setOutputDivActionVisibility(false);
    }, 1300);
  };

  const handleFeedbackToggle = (isPositive: boolean) => {
    setIsFeedBackPositive(isPositive);
    setShowFeedbackPopup(true);
  };

  const handleEditChat = () => {
    if (!editChatLoading && !disableTick) {
      const editedChat = chatResponse.replace(/<br>/g, '\n').replace(/\r|<\/?div>/g, '').trim();
      dispatch(actions.editChatResponse({
        responseId,
        chat: editedChat,
      }));
      dispatch(flurryActions.callFlurryEvent(
        valueToObject('xcgpt_editResponse_saved', startTime, {
          responseId,
          chat: editedChat,
          host,
        }),
      ));
      setOutputDivActionVisibility(false);
    }
  };

  useEffect(() => {
    if (!editChatLoading && prevEditChatLoading) {
      onCross();
    }
  }, [editChatLoading]);

  const handleOutputChange = (e: React.FormEvent<HTMLDivElement>) => {
    const { value } = e.target as HTMLTextAreaElement;
    setChatResponse(value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      setEnterPressed(true);
    }
    if (event.ctrlKey) {
      if (event.key === 'b' || event.key === 'B') {
        event.preventDefault();
      } else if (event.key === 'i' || event.key === 'I') {
        event.preventDefault();
      } else if (event.key === 'u' || event.key === 'U') {
        event.preventDefault();
      }
    }
  };

  useEffect(() => {
    const regex = /(<([^>]+)>)/gi;
    if (chatResponse.replace(regex, '').trim().length === 0 || chatResponse === chat.replace(/\n/g, '<br>')) {
      setDisableTick(true);
    } else {
      setDisableTick(false);
    }
  }, [chatResponse]);

  const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
    event.preventDefault();
    const pastedText = event.clipboardData.getData('text/plain');
    const sanitizedText = removeHtmlTags(pastedText);
    document.execCommand('insertText', false, sanitizedText);
  };

  useEffect(() => {
    if (enterPressed) {
      handleEditChat();
      setEnterPressed(false);
    }
  }, [enterPressed]);

  const handleEditClick = () => {
    setOutputDivActionVisibility(true);
    setChatResponse(chat.replace(/\n/g, '<br>').replace(/\r/g, ''));
    setShowEditResponse(true);
    onEditClickFn(id);
    setDisableTick(true);
    dispatch(actions.setEditInProgressId(id));
    dispatch(flurryActions.callFlurryEvent(
      valueToObject('xcgpt_editResponse_clicked', startTime, {
        host,
      }),
    ));
  };

  const handleClickChat = (event: React.MouseEvent<HTMLElement>) => {
    if (event.detail === 2) {
      handleEditClick();
    }
  };

  const handleDeleteClick = () => {
    setShowDeletePopup(true);
    dispatch(flurryActions.callFlurryEvent(
      valueToObject('xcgpt_deleteResponse_clicked', startTime, {
        host,
      }),
    ));
  };

  const handleDeleteConfirm = () => {
    if (chatHistory?.length > 1) {
      dispatch(actions.deleteChat({
        isStopGenerating: false,
        channelId: id,
        chatIds: id.toString(),
      }));
      dispatch(flurryActions.callFlurryEvent(
        valueToObject('xcgpt_deleteResponse_confirm', startTime, { chatIds: id.toString(), host }),
      ));
    } else {
      dispatch(actions.deleteConversation({
        channelId: selectedConvoId,
      }));
      dispatch(flurryActions.callFlurryEvent(
        valueToObject('xcgpt_deleteResponse_confirm', startTime, { channelId: id, host }),
      ));
    }
  };

  const handleDeleteCancel = () => {
    dispatch(flurryActions.callFlurryEvent(
      valueToObject('xcgpt_deleteResponse_cancel', startTime, {
        host,
      }),
    ));
  };

  return (
    <Wrapper className={classNames('output-js')} id={`output-js-${id}`}>
      <div className="left-side">
        <XiqGptEmailGilroySvg />
        {edited ? <span className="edited-tag">Edited</span> : null}
      </div>
      <div className="right-side">
        {showEditResponse && editInProgressId === id ? (
          <ContentEditable
            id=""
            className="output-content-editTable queryTitle"
            onChange={handleOutputChange}
            onPaste={handlePaste}
            onKeyDown={handleKeyDown}
            // innerRef={textAreaRef}
            html={chatResponse}
            suppressContentEditableWarning
            spellCheck="false"
            disabled={chatLoading}
            draggable={false}
            placeholder="Type a message..."
          />
        ) : (
          <span
            className={`queryTitle ${translateSkipAreas}`}
            onClick={handleClickChat}
            role="button"
            tabIndex={0}
          >
            {HTMLReactParser(chat.replace(/\r/g, ''))}
          </span>
        )}
      </div>
      {
        !showEditResponse || editInProgressId !== id ? (
          <OutputActionsDiv
            chatObj={chatObject}
            chatResponse={chatResponse}
            editToggle={handleEditClick}
            deleteToggle={handleDeleteClick}
            onRegenerate={onRegenerate}
            feedbackToggle={handleFeedbackToggle}
            hideFeedbackIcon={chatObject?.feedbackSubmitted || showDisclaimer}
            visibilityFlag={outputDivActionVisibility}
          />
        ) : (
          <div className="edit-action-div">
            <span
              className={classNames('tick', { disabled: disableTick || editChatLoading })}
              onClick={handleEditChat}
              onKeyPress={handleEditChat}
              role="button"
              tabIndex={0}
              aria-label="edit-tick"
            >
              <Tick />
            </span>
            <span
              className={classNames('cross', { disabled: false })}
              onClick={() => {
                onCross();
                dispatch(flurryActions.callFlurryEvent(
                  valueToObject('xcgpt_editResponse_cancel', startTime, {
                    host,
                  }),
                ));
              }}
              onKeyPress={() => onCross()}
              role="button"
              tabIndex={0}
              aria-label="edit-cross"
            >
              <Cross />
            </span>
          </div>
        )
      }
      <GilroyDeleteChatPopup
        isVisible={showDeletePopup}
        setIsVisible={setShowDeletePopup}
        popupText="Are you sure you want to delete this prompt?"
        onDelete={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
      />
      <GilroyFeedbackChatPopup
        isVisible={showFeedbackPopup}
        setIsVisible={setShowFeedbackPopup}
        chatId={responseId}
        isFeedbackPositive={isFeedbackPositive}
      />
    </Wrapper>
  );
};

export default GPTGeneratedOutput;
