import { createSelector } from '@reduxjs/toolkit';
import { initialState } from 'Slice/ChatbotV2Slice';

import { RootState } from 'Store/InjectorsTypes';

const selectDomain = (state: RootState) => state?.chatBotV2 || initialState;

export const selectShowLibrary = createSelector(
  [selectDomain],
  (state) => state.showLibrary,
);

export const selectConversationSections = createSelector(
  [selectDomain],
  (state) => state.converstationSections,
);

export const selectPromptCategories = createSelector(
  [selectDomain],
  (state) => state.promptCategories,
);

export const selectPromptCategoriesLoading = createSelector(
  [selectDomain],
  (state) => state.promptCategoriesLoading,
);
export const selectRecentTags = createSelector(
  [selectDomain],
  (state) => state.recentTags,
);

export const selectPrompts = createSelector(
  [selectDomain],
  (state) => state.prompts,
);

export const selectPromptsLoading = createSelector(
  [selectDomain],
  (state) => state.promptsLoading,
);

export const selectSelectedPromptCateogry = createSelector(
  [selectDomain],
  (state) => state.selectedPromptCategory,
);

export const selectEditConversationLoading = createSelector(
  [selectDomain],
  (state) => state.editConversationLoading,
);

export const selectChatHistory = createSelector(
  [selectDomain],
  (state) => state.chatHistory,
);

export const selectChatLoading = createSelector(
  [selectDomain],
  (state) => state.chatLoading,
);

export const selectedConversationId = createSelector(
  [selectDomain],
  (state) => state.selectedConversationId,
);

export const selectEditChatLoading = createSelector(
  [selectDomain],
  (state) => state.editChatLoading,
);

export const selectChatHistoryLoading = createSelector(
  [selectDomain],
  (state) => state.chatHistoryLoading,
);

export const selectShowExamplePrompts = createSelector(
  [selectDomain],
  (state) => state.showExamplePrompts,
);

export const selectPendingChatId = createSelector(
  [selectDomain],
  (state) => state.pendingChatId,
);

export const selectChatId = createSelector(
  [selectDomain],
  (state) => state.chatId,
);

export const selectSearchData = createSelector(
  [selectDomain],
  (state) => ({
    peopleSection: state.peopleSection,
    companiesSection: state.companiesSection,
    buyerSection: state.buyerGroupsSection,
    documentSection: state.documentSection,
    searchLoading: state.isGlobalSearchLoading,
    searchLoaded: state.isGlobalSearchLoaded,
    extendedSearchLoading: state.isPersonExtendedLoading,
    extendedSearchLoaded: state.isPersonExtendedLoaded,
    query: state.query,
    peoplePage: state.personPage,
    companiesPage: state.companyPage,
    buyerPage: state.buyerPage,
    documentPage: state.documentPage,
  }),
);

export const selectTaggedItem = createSelector(
  [selectDomain],
  (state) => state.taggedItem,
);

export const selectShowSearchPopup = createSelector(
  [selectDomain],
  (state) => state.showSearchPopup,
);

export const selectDeleteChat = createSelector(
  [selectDomain],
  (state) => state.deleteChat,
);

export const selectIsRegenerate = createSelector(
  [selectDomain],
  (state) => state.isRegenerate,
);

export const selectEditInProgressId = createSelector(
  [selectDomain],
  (state) => state.editInProgressId,
);

export const selectLimitPopup = createSelector(
  [selectDomain],
  (state) => state.limitPopup,
);

export const selectConversationsLoading = createSelector(
  [selectDomain],
  (state) => state.conversationsLoading,
);

export const selectDeleteConversationLoading = createSelector(
  [selectDomain],
  (state) => state.deleteConversationLoading,
);

export const selectDeleteConversationLoaded = createSelector(
  [selectDomain],
  (state) => state.deleteConversationLoaded,
);

export const selectTaggedList = createSelector(
  [selectDomain],
  (state) => state.taggedItemsList,
);
export const selectUniqueTagItemList = createSelector(
  [selectDomain],
  (state) => state.uniqueTagItemList,
);

export const selectPersonCardLoading = createSelector(
  [selectDomain],
  (state) => state.personCardLoading,
);

export const selectIdToReplace = createSelector(
  [selectDomain],
  (state) => state.idToReplace,
);

export const selectScrollOnConvoUpdate = createSelector(
  [selectDomain],
  (state) => state.scrollOnConvoUpdate,
);

export const selectSaveProfileNameV2 = createSelector(
  [selectDomain],
  (state) => state.saveProfileName,
);

export const selectSaveUrlV2 = createSelector(
  [selectDomain],
  (state) => state.saveUrl,
);

export const selectGeneratingChannelId = createSelector(
  [selectDomain],
  (state) => state.generatingChannelId,
);

export const selectLimitPopupVisible = createSelector(
  [selectDomain],
  (state) => state.limitPopupVisible,
);

export const selectTagSetupLoading = createSelector(
  [selectDomain],
  (state) => state.tagSetupLoading,
);

export const selectIsNewChat = createSelector(
  [selectDomain],
  (state) => state.isNewChat,
);

export const selectSearchQuery = createSelector(
  [selectDomain],
  (state) => state.searchQuery,
);

export const selectPromptInProgress = createSelector(
  [selectDomain],
  (state) => state.promptInProgress,
);

export const selectPlaceholderToReplace = createSelector(
  [selectDomain],
  (state) => state.placeholderToReplace,
);

export const selectClickedCertificationPrompt = createSelector(
  [selectDomain],
  (state) => state.certificationPrompt,
);

export const selectHistoryPanelOpened = createSelector(
  [selectDomain],
  (state) => state.historyPanelOpened,
);

export const selectDrawerPreviewOpen = createSelector(
  [selectDomain],
  (state) => state.drawerPreviewOpen,
);

export const selectShowFavorite = createSelector(
  [selectDomain],
  (state) => state.showFavorite,
);

export const selectFavPromptAdded = createSelector(
  [selectDomain],
  (state) => state.favPromptAdded,
);

export const selectFavPromptRemoved = createSelector(
  [selectDomain],
  (state) => state.favPromptRemoved,
);

export const selectRemovedPromptId = createSelector(
  [selectDomain],
  (state) => state.removedPromptId,
);

export const selectFavPromptCount = createSelector(
  [selectDomain],
  (state) => state.favPromptCount,
);

export const selectMarkFavoriteLoadingId = createSelector(
  [selectDomain],
  (state) => state.markFavoriteLoadingId,
);

export const selectCustomCategories = createSelector(
  [selectDomain],
  (state) => state.promptCustomCategories,
);

export const selectCustomCategoriesLoading = createSelector(
  [selectDomain],
  (state) => state.customCategoriesLoading,
);

export const selectTotalCustomCategories = createSelector(
  [selectDomain],
  (state) => state.totalCustomCategories,
);

export const selectCompanyName = createSelector(
  [selectDomain],
  (state) => state.companyName,
);

export const selectCategoryPermission = createSelector(
  [selectDomain],
  (state) => state.categoryPermission,
);

export const selectSuggestedCategories = createSelector(
  [selectDomain],
  (state) => state.suggestedCategories,
);

export const selectUnselectedPersonIds = createSelector(
  [selectDomain],
  (state) => state.unselectedPersonIds,
);

export const selectTotalPrompts = createSelector(
  [selectDomain],
  (state) => state.totalPrompts,
);

export const selectEditCategoryLoading = createSelector(
  [selectDomain],
  (state) => state.editCategoryLoading,
);

export const selectDeleteCategoryLoading = createSelector(
  [selectDomain],
  (state) => state.deleteCategoryLoading,
);

export const selectAddRemovePromptLoading = createSelector(
  [selectDomain],
  (state) => state.addRemovePromptLoading,
);

export const selectIsNewCategoryOrPrompt = createSelector(
  [selectDomain],
  (state) => state.isNewCategoryOrPrompt,
);
