/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  SecondaryColor,
  midWidthApp,
  primaryFont,
  wMedium,
} from 'Utils/utilsStyle';
import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.span`
 &.category-type {
  &.activeCategoryWrapper {
    background-color: ${SecondaryColor};
    color: #fff;
  }
    cursor: pointer;
    font-family: ${primaryFont};
    font-size: 12px;
    font-weight: ${wMedium};
    letter-spacing: -0.567px;
    color: ${SecondaryColor};
    border-radius: 25px;
    border: 1px solid ${SecondaryColor};
    padding: 1px 6px;
    margin-right: 4px;
    white-space: pre;
    @media all and (max-width: ${midWidthApp}) {
      font-size: 10px;
      padding: 0px 6px;
      height: 14px;
    }
  }
`;
type Props = {
  fText?: string,
  categoryClass?: string,
  onClickCategory?: (a: string) => void,
  activeCategory?: boolean,
} & typeof defaultProps;
const defaultProps = {
  fText: 'Sales Enablement',
  categoryClass: '',
  onClickCategory: (a: string) => { },
  activeCategory: false,
};
const CategoryPill = function CategoryPill(props: Props) {
  const {
    fText, categoryClass, onClickCategory, activeCategory,
  } = props;
  return (
    <Wrapper
      tabIndex={0}
      role="button"
      onClick={() => onClickCategory(fText)}
      onKeyPress={() => onClickCategory(fText)}
      className={classNames(`category-type ${categoryClass}`, { activeCategoryWrapper: activeCategory })}
    >
      {fText}
    </Wrapper>
  );
};
CategoryPill.defaultProps = defaultProps;
export default CategoryPill;
