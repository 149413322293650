/* eslint-disable no-new */
/* eslint-disable max-len */
/* eslint-disable no-cond-assign */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable radix */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import Cookies from 'js-cookie';
import OneSignalReact from 'react-onesignal';
import { GroupPersonality } from 'Types/GroupsTypes';
import { User } from 'Types/LoginTypes';
import { PersonalityTypes, ArcheType } from 'Types/PersonTypes';
import { io } from 'socket.io-client';
import { Pulse } from 'Types/PulseTypes';
import _ from 'lodash';
import { AddNoteRequestPayload, FlurryEventRequest } from 'Types/RequestBodies';
import { useMediaQuery } from 'react-responsive';
import { SLFilter } from 'Types/ServiceLinesTypes';
import { Filter } from 'Types/FilterTypes';
import { CertificationData, PageData } from 'Types/CertificationTypes';
import { EntityComment, PersonMentions } from 'Types/NotesTypes';
import classNames from 'classnames';
import { notification } from 'antd';
import { TagItem } from 'Types/ChatBotV2Types';
import { Article } from 'Types/ArticleTypes';
import { DropdownOptions } from 'Translation/Options';
import { CompanyPersonality, CompanyProfile } from '../Types/CompaniesTypes';
import { ObjectToForm, ViewInterface } from '../Types/GlobalTypes';
import {
  ARCTYPES,
  CERTIFICATION_COMP_POPUPS,
  CERTIFICATION_START_POPUP,
  COMPANIES_ROUTE,
  COMPANY_NEWS_ROUTE,
  COMPANY_OVERVIEW_ROUTE,
  DOCUMENTS_ROUTE,
  FEED_GILROY_ROUTE,
  FEED_NEWS_ROUTE,
  GROUP_DETAIL_ROUTE,
  ONBOARDING_ROUTE,
  PEOPLE_ROUTE,
  PERSON,
  PERSONALITY_INSIGHTS_ROUTE,
  PROFILE,
  API,
} from './Constants';
import {
  alertArticlePlaceholder, pdfFileIcon, pptFileIcon, txtFileIcon, wordFileIcon,
} from './utilsStyle';

const socketUrl: string = process.env.REACT_APP_WEBSOCKET_URL || 'https://websocket-dev.xiq.io:27018/';
const socketUrlAP = process.env.REACT_APP_WEBSOCKET_URL_AP
  || 'https://websocket-dev.xiq.io:27018/';
const IMAGE_URL: string | undefined = process.env.REACT_APP_IMAGE_SERVER_URL;
export const getLocalStorageValue = (key: string): string | null => localStorage
  .getItem(key);
export const setLocalStorageValue = (key: string, value: string) => localStorage
  .setItem(key, value);
export const socket = io(socketUrl, {
  autoConnect: false,
  secure: true,
  query: {
    username: getLocalStorageValue('email'),
    identifier: 'xiQwebapp',
    environment: 'PROD',
  },
});
export const accountPlanSocket = io(socketUrlAP, {
  autoConnect: false,
  secure: true,
  query: {
    username: getLocalStorageValue('email'),
    identifier: 'account_plan',
    environment: 'PROD',
  },
  reconnection: true,
  reconnectionAttempts: Infinity,
  reconnectionDelay: 1000,
});
export const isActive = (): boolean => {
  let active = false;
  const csrfToken: string | null | undefined = getLocalStorageValue('csrfToken');
  if (csrfToken && csrfToken !== '') {
    active = true;
  }
  return active;
};

export const isGuestfromEmail = () : boolean => {
  const url = window.location.href;
  const urlObj = new URL(url);
  const params = new URLSearchParams(urlObj.search);
  return (params.get('personType') === 'EXECUTIVE' || params.get('personType') === 'LIVESEARCH') && (params.get('isGuest')?.toLowerCase() === 'true');
};

export const getQueryParamValue = (paramName: string): string | null => {
  if (typeof window !== 'undefined' && window.location) {
    const urlSearchParams = new URLSearchParams(window.location.search);
    return urlSearchParams.get(paramName);
  }
  return null;
};

export const getStringAfterQueryParam = (param: string): string | null => {
  if (typeof window !== 'undefined' && window.location) {
    const queryString = window.location.search;
    const paramIndex = queryString.indexOf(param);
    if (paramIndex !== -1) {
      const startIndex = paramIndex + param.length;
      return queryString.substring(startIndex);
    }
  }
  return null;
};

export const getSessionStorageValue = (key: string): string | null => sessionStorage
  .getItem(key);
export const setSessionStorageValue = (key: string, value: string) => sessionStorage
  .setItem(key, value);

export const isFreeUser = (): boolean => {
  let flag = false;
  const userPlan = getLocalStorageValue('userPlan');
  if (userPlan && userPlan === 'FREE') {
    flag = true;
  }
  return flag;
};

export const isEnterpriseUser = (): boolean => {
  let flag = false;
  const userPlan = getLocalStorageValue('userPlan')?.toUpperCase();
  if (userPlan && userPlan === 'ENTERPRISE') {
    flag = true;
  }
  return flag;
};

export const getTimezoneInGMT = (): string => {
  const date = new Date();
  const utcOffsetMinutes = date.getTimezoneOffset();
  const utcOffsetHours = -utcOffsetMinutes / 60;
  const sign = utcOffsetHours >= 0 ? '+' : '-';
  return `GMT${sign}${Math.abs(utcOffsetHours).toString().padStart(2, '0')}00`;
};

export const isGuest = (): boolean => {
  let guest = false;
  const username: string = getLocalStorageValue('username') || '';
  if (username === 'guest@xiqinc.com') {
    guest = true;
  }
  return guest;
};
export const isSafariBrowser = () => {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  return isSafari;
};

export const checkInitialRoute = () => getSessionStorageValue('initialRoute')?.length
  && getSessionStorageValue('initialRoute')?.includes('/feed');

export const isSalesforce = () => {
  let flag = false;
  if (window.location.hostname.includes('salesforce-salesapp')) {
    if (checkInitialRoute()) {
      flag = false;
    } else {
      flag = true;
    }
  }
  return flag;
};
export const isSalesforceRoute = () => {
  if (window.location.hostname.includes('salesforce-salesapp')) return true;

  return false;
};
export const isOnboardingRoute = () => {
  if (window.location.pathname === ONBOARDING_ROUTE) return true;

  return false;
};

export const haveHistory = () => {
  if (window.history.state && window.history.state.idx >= 1) {
    return true;
  }
  return false;
};

export const setLocalStorageObject = (key: string, value: any) => {
  localStorage.setItem(key, JSON.stringify(value));
};
export const getLocalStorageObject = (key: string) => {
  const data = localStorage.getItem(key);
  const parse = JSON.parse(data || '{}');
  return parse;
};

export const formatFirstLetterToLower = (sentence: string) => {
  if (!sentence) return '';
  const words = sentence.split(' ');
  const formattedWords = words.map(
    (word, index) => (index === 0 ? word : word.charAt(0).toLowerCase() + word.slice(1)),
  );
  return formattedWords.join(' ');
};

export const guestRouteCheck = (): boolean => {
  if (
    window.location.pathname.includes(`/${PERSON}`)
    || window.location.pathname.includes(`/${ARCTYPES}`)
  ) {
    return true;
  }
  return false;
};
export const skipOnboarding = (): boolean => {
  const skipOnBoarding: string | null | undefined = getLocalStorageValue('skipOnBoarding');
  if (skipOnBoarding && skipOnBoarding === '1') return true;
  return false;
};
export const existsInLocalStorage = (key: string): boolean => {
  const exist: string | null | undefined = getLocalStorageValue(key);
  if (exist && exist !== '') {
    return true;
  }

  return false;
};

const getDataToAppend = (objectItem: object | string) => {
  const typeOfObjectItem = typeof objectItem;
  if (objectItem instanceof File) return objectItem;
  if (typeOfObjectItem === 'object') {
    return JSON.stringify(objectItem);
  }
  return String(objectItem);
};

export const objToFormData: ObjectToForm = (obj) => Object.keys(obj)
  .reduce((formData: FormData, key: string) => {
    formData.append(key, getDataToAppend(obj[key]));
    return formData;
  }, new FormData());

export const setCookie = (key: string, value: string) => {
  const date = new Date(new Date().getTime());
  const { hostname } = window.location;

  const opts = {
    expires: new Date(date.setMonth(date.getMonth() + 6)), // 6 months
    domain: '.xiqonline.com',
    secure: true,
  };
  if (hostname === 'localhost') {
    Cookies.set(key, value);
  } else {
    Cookies.set(key, value, opts);
  }
};

export const removeCookie = (key: string) => {
  const date = new Date(new Date().getTime());
  const opts = {
    domain: '.xiqonline.com',
    secure: true,
  };
  Cookies.remove(key, opts);
};

export const getCookie = (key: string): string | null | undefined => Cookies.get(key);

export const existsInCookies = (key: string): boolean => {
  const exist: string | null | undefined = Cookies.get(key);
  if (exist && exist !== '') {
    return true;
  }

  return false;
};

export const parseColorGradients = (
  obj: Pulse[] | CompanyPersonality[] | GroupPersonality[],
): string => {
  let coloring = '';
  if (obj.length === 1) {
    coloring += `${obj[0]?.color}`;
  } else {
    obj.map((p, i) => {
      if (p.start === '0%') {
        coloring += ` ${p.color} ${p.end},`;
      } else if (i === obj.length - 1) {
        coloring += ` ${p.color} ${p.start}, ${p.color} ${p.end}`;
      } else {
        coloring += ` ${p.color} ${p.start}, ${p.color} ${p.end},`;
      }

      return null;
    });
  }
  return coloring;
};

export const companyColorParser = (
  obj: Pulse[] | CompanyPersonality[] | GroupPersonality[],
): string => {
  let coloring = '';
  if (Array.isArray(obj) && obj.length === 1) {
    coloring += `${obj[0]?.color}`;
  } else if (Array.isArray(obj) && obj.length > 1) {
    obj.map((p, i) => {
      if (p.start === '0%') {
        coloring += ` ${p.color},`;
      } else if (i === obj.length - 1) {
        coloring += ` ${p.color}`;
      } else {
        coloring += ` ${p.color},`;
      }
      return null;
    });
  } else {
    return '#8d8585 50%, #8d8585 100%';
  }
  return coloring;
};

export const dummyCompanyPluse = () => [
  {
    color: '8d8585',
    percentage: 0,
    start: '0%',
    end: '',
  },
  {
    color: '8d8585',
    percentage: 0,
    start: '100%',
    end: '',
  },
];

export const colorGradientsWithoutMix = (
  obj: Pulse[] | CompanyPersonality[] | GroupPersonality[],
): string => {
  let coloring = '';
  if (Array.isArray(obj) && obj.length === 1) {
    coloring += `${obj[0]?.color}`;
  } else if (Array.isArray(obj) && obj.length > 1) {
    obj.map((p, i) => {
      if (p.start === '0%') {
        coloring += ` ${p.color} ${p.end},`;
      } else if (i === obj.length - 1) {
        coloring += ` ${p.color} ${p.end}`;
      } else {
        coloring += ` ${p.color} ${p.end},`;
      }
      return null;
    });
  }
  return coloring;
};

export const parseColorGradientMixture = (
  obj: Pulse[] | CompanyPersonality[] | GroupPersonality[],
): string => {
  const personality = obj;
  let coloring = '';
  if (personality.length) {
    if (personality.length === 1) {
      coloring += `${personality[0]?.color} 0deg,${personality[0]?.color} 360deg `;
    } else {
      personality
        && personality?.forEach((p: any, i: any) => {
          if (p.start === '0%') {
            coloring += ` ${p.color} ${`${360 / (100 / parseInt(p.end))}deg`},`;
          } else if (i === personality.length - 1) {
            coloring += `${p.color} ${`${
              360 / (100 / parseInt(p.end)) - 10
            }deg`},`;
            coloring += `${personality[0]?.color}`;
          } else {
            coloring += `${p.color} ${`${360 / (100 / parseInt(p.end))}deg`},`;
          }
          return null;
        });
    }
  }
  return coloring;
};

export const setImageSize = (
  imgUrl: string,
  width: number | string,
  height: number | string,
  articleId: number,
) => {
  if (articleId !== 0) {
    return `${IMAGE_URL}returnimage/?url=${encodeURIComponent(
      imgUrl,
    )}&width=${width}&height=${height}&articleid=${articleId}`;
  }
  return imgUrl;
};
export default setImageSize;

export const validateSearchQuery = (query: string) => query.replace(/\s\s+/g, ' ').replace(/[\\#~%|]/g, '');

export const backgroundColorParsing = (personality: PersonalityTypes[]) => {
  if (personality.length < 1) return '#979797 50%, #979797 50% ';
  if (personality.length > 1) {
    return `${personality[0].color} 50%, ${personality[1].color} 100%`;
  }
  return `${personality[0].color} 50%, ${personality[0].color} 100%`;
};

export const colorParsing = (personality: ArcheType) => `${personality.color} 50%, ${personality.color} 100%`;

export const demoPulse = [
  {
    description: '',
    color: '#ff0000',
    image: '',
    key: '',
    value: '',
  },
];

export const demoPulseUpdated = [
  {
    name: '',
    color: '#ff0000',
    percentage: '',
    key: '',
    start: '',
    end: '',
  },
];

export const allValueInKeyOfArrayExists = (
  array: any,
  key: string,
  value: any,
): boolean => array?.every((v: any) => v[key] === value);

export const allKeysHaveLengthInArray = (array: any, key: string): boolean => array?.every(
  (v: any) => v[key] && v[key].length,
);
export const anyValueInKeyOfArrayExists = (
  array: any,
  key: string,
  value: any,
): boolean => array.some((v: any) => v[key] === value);

export const getQueryPersonId = () => {
  // get version id from url param or state
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const personId = params.get('personId');
  return personId ? parseInt(personId, 10) : 0;
};
export const getId = () => {
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const id = params.get('id');
  return id ? parseInt(id, 10) : 0;
};

export const getEmail = () => getLocalStorageValue('email');

export const getQueryPersonType = () => {
  // get version id from url param or state
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const personType = params.get('personType');
  return personType || 'EXECUTIVE';
};

export const navigateToAuthServer = (location = '/', isProfileLogin = false) => {
  const authServer: string = process.env.REACT_APP_AUTH_SERVER_URL || '';
  const callBackUrl: string = process.env.REACT_APP_CALLBACK_URL || '';
  window.location.href = `${authServer}?${isProfileLogin ? 'isLogout=1' : ''}&redirectURL=${callBackUrl}${
    location !== '/' && !location.includes('/sso')
      ? `&navigateTo=${location}`
      : ''
  }`;
};

export const resetOnlogout = (location = '/', setIsLogout = false, isProfileLogin = false) => {
  // eslint-disable-next-line no-console
  console.log('reset logout called');
  const welcomePopupFlag = getLocalStorageValue('welcomePopupFlag');
  const getArticleView = getLocalStorageObject('ViewTab') as ViewInterface;
  const certificationFlag = getLocalStorageValue(CERTIFICATION_START_POPUP);
  const popupFlag = getLocalStorageValue(CERTIFICATION_COMP_POPUPS);
  localStorage.clear();
  if (setIsLogout) setCookie('isLogout', '1');
  if (welcomePopupFlag) {
    setLocalStorageValue('welcomePopupFlag', 'true');
  }
  if (getArticleView) {
    setLocalStorageObject('ViewTab', { view: getArticleView.view });
  }
  if (certificationFlag) {
    setLocalStorageValue(CERTIFICATION_START_POPUP, 'true');
  }
  if (popupFlag) {
    setLocalStorageValue(CERTIFICATION_COMP_POPUPS, 'true');
  }
  navigateToAuthServer(location, isProfileLogin);
};

export const getPushToken = () => {
  OneSignalReact.getUserId((userId) => {
    // eslint-disable-next-line no-console
    console.log(userId);
    return userId;
  }).catch(() => '');
  return '';
};
export const getAppName = () => {
  if (window.location.hostname.includes('salesforce')) return 'salesforce';
  return 'xiq-webapp';
};

export const saveUserDetails = (userInfo: User, rememberMe = false, isGuest = false) => {
  let personalityColors;
  if (userInfo?.profilePic) {
    setLocalStorageValue('profilePic', userInfo.profilePic);
  }
  if (userInfo?.personCard && userInfo?.personCard.length) {
    personalityColors = backgroundColorParsing(
      userInfo?.personCard[0]?.personalityTypes || [],
    );
    setLocalStorageValue(
      'personDesignation',
      userInfo?.personCard[0].personDesignation,
    );
  }

  if (userInfo?.userType) {
    setLocalStorageValue('userType', userInfo.userType);
  }
  // if (userInfo?.phone) {
  //   setLocalStorageValue('phone', userInfo.phone);
  // }
  setLocalStorageValue('csrfToken', userInfo.csrfToken);
  if (isGuest) {
    setLocalStorageValue('active', '0');
    setLocalStorageValue('username', 'guest@xiqinc.com');
  } else {
    setLocalStorageValue('active', userInfo.active ? '1' : '0');
    setLocalStorageValue('username', '');
    setLocalStorageValue('userPlan', userInfo.userPlan);
  }
  setLocalStorageValue('email', userInfo.email);
  setLocalStorageValue('fullname', userInfo.fullName);
  setLocalStorageValue('skipOnBoarding', userInfo.skipOnBoarding ? '1' : '0');
  setLocalStorageValue(
    'subscription',
    userInfo.emailNotification === 1 ? 'True' : 'False',
  );
  setLocalStorageValue('access_id', userInfo.jwt);

  if (personalityColors) {
    setLocalStorageValue('personalitycolors', personalityColors);
  }

  if (userInfo.companyName && userInfo.companyName.length) {
    setLocalStorageValue('personCompany', userInfo.companyName);
  }
  if (userInfo.userCompliancePopup && Object.keys(userInfo.userCompliancePopup).length) {
    setLocalStorageValue('userCompliancePopup', JSON.stringify(userInfo.userCompliancePopup));
  }
  if (rememberMe) {
    setCookie('userName', userInfo.fullName);
    setCookie('image', userInfo.profilePic);
    setCookie('email', userInfo.email);
  }
  setLocalStorageValue('xiqtrans', userInfo.defaultLanguage || 'en');
};
export const isAPrivateCompany = (companyProfile: CompanyProfile) => companyProfile?.companyFinancialInfo?.status.toLowerCase() === 'private';

export const teamsShareLauncherFunction = () => {
  const script = document.createElement('script');
  script.src = 'https://teams.microsoft.com/share/launcher.js';
  script.async = true;
  document.body.appendChild(script);
  return () => {
    document.body.removeChild(script);
  };
};

export const isGenpact = () => localStorage.getItem('userType') === 'genpact';
export const isDell = () => localStorage.getItem('personCompany') === 'Dell';
export const isDuke = () => localStorage.getItem('personCompany') === 'Duke Energy';

// Branch start
export const getBranchKey = () => {
  let branchKey = process.env.REACT_APP_BRANCH_IO_KEY || '';
  if (isGenpact()) {
    branchKey = process.env.REACT_APP_BRANCH_IO_GENPACT_KEY || '';
  }
  return branchKey;
};
export const getSessionId = () => {
  let sessionId = sessionStorage.getItem('branch_session');
  if (sessionId) {
    sessionId = JSON.parse(sessionId).session_id as string | null;
  }
  return sessionId;
};

export const getCurrentTime = () => new Date().getTime() / 1000;

export const getCurrentDate = (): string => {
  const now = new Date();
  return now.toLocaleDateString();
};

export const valueToObject = (name: string, time: number, info?: any) => {
  const snakeToCamel: { [key: string]: string | number } = {};

  for (const i in info) {
    snakeToCamel[
      i
        .split(/(?=[A-Z])/)
        .join('_')
        .toLowerCase()
    ] = info[i];
  }

  if ('page' in snakeToCamel) {
    snakeToCamel.current_page = snakeToCamel.page;
    _.unset(snakeToCamel, 'page');
  }
  const obj = {
    eventname: name,
    ...(info
      && !info.isDeleteTime && {
      loading_time: _.round(getCurrentTime() - time, 2),
    }),
    session_id: getSessionId(),
    version: 2.0,
    ...snakeToCamel,
  };

  if ('is_delete_time' in obj) {
    _.unset(obj, 'is_delete_time');
  }

  if ('is_called' in obj) {
    _.unset(obj, 'loading_time');
    _.unset(obj, 'current_page');
    _.unset(obj, 'is_called');
  }

  return obj as FlurryEventRequest;
};

export const getQueryUserId = () => {
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const personId = params.get('userId');
  return personId ? parseInt(personId, 10) : 0;
};

export const loginNavigation = (url = '') => {
  const route: string = window.location.search || '/feed';
  if (isActive()) {
    if (url !== '') {
      window.location.replace(url);
    } else {
      const [address, ...others] = route.split('/');
      if (others.length) {
        window.location.replace(`/${others.join('/')}`);
      } else {
        window.location.replace(address);
      }
    }
  }
};

export const MaxWidthApp = () => {
  const isResolution = useMediaQuery({
    query: '(max-width: 1500px)',
  });
  return isResolution;
};
export const Retina125 = () => {
  const isResolution = useMediaQuery({
    query: '(-webkit-device-pixel-ratio: 1.25)',
  });
  return isResolution;
};
export const MaxWidthAppV2 = () => {
  const isResolution = useMediaQuery({
    query: '(max-width: 1450px)',
  });
  return isResolution;
};

export const MidWidthApp = () => {
  const isResolution = useMediaQuery({
    query: '(max-width: 1280px)',
  });
  return isResolution;
};
export const MidWidth1300 = () => {
  const isResolution = useMediaQuery({
    query: '(max-width: 1300px)',
  });
  return isResolution;
};
export const MidWidthApp1200 = () => {
  const isResolution = useMediaQuery({
    query: '(max-width: 1200px)',
  });
  return isResolution;
};

export const MinWidthApp = () => {
  const isResolution = useMediaQuery({
    query: '(max-width: 1100px)',
  });
  return isResolution;
};

export const IsTablet = () => {
  const isResolution = useMediaQuery({
    query: '(max-width: 1023px)',
  });
  return isResolution;
};

export const MidTablet = () => {
  const isResolution = useMediaQuery({
    query: '(max-width: 993px)',
  });
  return isResolution;
};

export const IsMobile = () => {
  const isResolution = useMediaQuery({
    query: '(max-width: 767px)',
  });
  return isResolution;
};

export const IsMobileSm = () => {
  const isResolution = useMediaQuery({
    query: '(max-width: 577px)',
  });
  return isResolution;
};
export const IsMobileM = () => {
  const isResolution = useMediaQuery({
    query: '(max-width: 650px)',
  });
  return isResolution;
};

export const IsMobileSm400 = () => {
  const isResolution = useMediaQuery({
    query: '(max-width: 400px)',
  });
  return isResolution;
};

export const XsmHeight = () => {
  const isResolution = useMediaQuery({
    query: '(max-height: 690px)',
  });
  return isResolution;
};

export const IsMobileXsm = () => {
  const isResolution = useMediaQuery({
    query: '(max-width: 380px)',
  });
  return isResolution;
};

export const IsWidth1300 = () => {
  const isResolution = useMediaQuery({
    query: '(max-width: 1300px)',
  });
  const endResolution = useMediaQuery({
    query: '(min-width: 1023px)',
  });
  if (isResolution && endResolution) {
    return true;
  }
  return false;
};

export const SlicerResultPage = () => {
  const minMobile = useMediaQuery({
    query: '(max-width: 480px)',
  });
  const midMobile = useMediaQuery({
    query: '(max-width: 650px)',
  });
  const maxMobile = useMediaQuery({
    query: '(max-width: 768px)',
  });
  const max = useMediaQuery({
    query: '(max-width: 1450px)',
  });
  let value = 6;
  if (minMobile) value = 2;
  else if (midMobile) value = 4;
  else if (maxMobile) value = 4;
  else if (max) value = 6;
  return value;
};

export const GroupSlicerResultPage = () => {
  const minMobile = useMediaQuery({
    query: '(max-width: 480px)',
  });
  const midMobile = useMediaQuery({
    query: '(max-width: 650px)',
  });
  const maxMobile = useMediaQuery({
    query: '(max-width: 768px)',
  });
  const max = useMediaQuery({
    query: '(max-width: 1450px)',
  });

  let value = 6;
  if (minMobile) value = 2;
  else if (midMobile) value = 4;
  else if (maxMobile) value = 4;
  else if (max) value = 6;
  return value;
};

export const ArticleSlicerResultPage = () => {
  const minMobile = useMediaQuery({
    query: '(max-width: 576px)',
  });
  const maxMobile = useMediaQuery({
    query: '(max-width: 768px)',
  });

  const value = 5;
  // if (minMobile) value = 2;
  // else if (maxMobile) value = 4;
  return value;
};

export const convertToFile = (dataurl: string, filename: string) => {
  const arr: string[] = dataurl.split(',');
  const obj = arr[0].match(/:(.*?);/);
  const mime = obj ? obj[1] : '';
  const bstr = window.atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n) {
    n -= 1;
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

export const generateRandomFileName = () => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < 8; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  result += '.png';
  return result;
};

export const mergeFilters = (filterTypes: SLFilter[]) => {
  let payloadMarketFilter: Filter[] = [];
  filterTypes?.forEach((t) => {
    payloadMarketFilter = [
      ...payloadMarketFilter,
      ...t.filters_list.map((v) => ({ ...v, isSelected: true })),
    ];
  });
  return payloadMarketFilter;
};

export const scrollWithId = (element: HTMLElement | null, extra = 0) => {
  if (element) {
    const yOffset = -120;
    const yScroll = element.getBoundingClientRect().top
      + window.pageYOffset
      + yOffset
      - extra;
    window.scrollTo({ top: yScroll, behavior: 'smooth' });
  }
};

export const smoothBehavior = (id = 'tabs-details') => {
  const element: HTMLElement | null = document.getElementById(id);
  scrollWithId(element);
};

export const certificationSmoothBehavior = (scrollId = 'tabs-details') => {
  const id = scrollId;
  const element: HTMLElement | null = document.getElementById(id);
  scrollWithId(element, 25);
};

export const getPageUrl = (data: PageData) => {
  const {
    type, personId, companyId, groupId,
  } = data;
  const redirection = {
    page: '',
    id: '',
  };
  switch (type) {
    case 'spotlight_article_select':
      redirection.page = FEED_GILROY_ROUTE;
      redirection.id = 'certification-spotlight';
      return redirection;

    case 'pulse_filter':
      redirection.page = FEED_GILROY_ROUTE;
      redirection.id = 'certification-pulse';
      return redirection;

    case 'account_sales_tab':
      redirection.page = FEED_NEWS_ROUTE;
      redirection.id = 'certification-feed-tabs';
      return redirection;

    case 'person_search':
      redirection.page = PEOPLE_ROUTE;
      redirection.id = 'certification-global-search';
      return redirection;

    case 'personality_insights':
      redirection.page = `${PERSONALITY_INSIGHTS_ROUTE}${personId}&personType=EXECUTIVE`;
      redirection.id = 'certification-person-header';
      return redirection;

    case 'affinity_section':
      redirection.page = `${PERSONALITY_INSIGHTS_ROUTE}${personId}&personType=EXECUTIVE`;
      redirection.id = 'certification-person-affinity';
      return redirection;

    case 'person_insights':
      redirection.page = `${PERSONALITY_INSIGHTS_ROUTE}${personId}&personType=EXECUTIVE`;
      redirection.id = 'certification-person-header';
      return redirection;

    case 'person_download_header':
      redirection.page = `${PERSONALITY_INSIGHTS_ROUTE}${personId}&personType=EXECUTIVE`;
      redirection.id = 'certification-person-header';
      return redirection;

    case 'person_share_header':
      redirection.page = `${PERSONALITY_INSIGHTS_ROUTE}${personId}&personType=EXECUTIVE`;
      redirection.id = 'certification-person-header';
      return redirection;

    case 'person_note_write':
      redirection.page = `${PERSONALITY_INSIGHTS_ROUTE}${personId}&personType=EXECUTIVE`;
      redirection.id = 'certification-person-header';
      return redirection;

    case 'company_search':
      redirection.page = COMPANIES_ROUTE;
      redirection.id = 'certification-global-search';
      return redirection;

    case 'company_sales':
      redirection.page = `${COMPANY_NEWS_ROUTE}${companyId}`;
      redirection.id = 'certification-company-tabs';
      return redirection;

    case 'company_download_header':
      redirection.page = `${COMPANY_NEWS_ROUTE}${companyId}`;
      redirection.id = 'certification-company-tabs';
      return redirection;

    case 'company_share_header':
      redirection.page = `${COMPANY_NEWS_ROUTE}${companyId}`;
      redirection.id = 'certification-company-tabs';
      return redirection;

    case 'people_dashboard':
      redirection.page = `${PEOPLE_ROUTE}`;
      redirection.id = 'certification-buyer-groups';
      return redirection;

    case 'person_drag':
      redirection.page = `${PEOPLE_ROUTE}`;
      redirection.id = 'certification-people-follow';
      return redirection;

    case 'company_profile':
      redirection.page = `${COMPANY_NEWS_ROUTE}${companyId}`;
      redirection.id = 'certification-company-tabs';
      return redirection;

    case 'buyer_group':
      if (groupId > 0) {
        redirection.page = `${GROUP_DETAIL_ROUTE}${groupId}`;
        redirection.id = 'certification-buyer-groups';
      } else {
        redirection.page = PROFILE.APPLE;
        redirection.id = 'certification-company-tabs';
      }
      return redirection;

    case 'buyer_navigate':
      if (groupId > 0) {
        redirection.page = `${GROUP_DETAIL_ROUTE}${groupId}`;
        redirection.id = 'certification-buyer-groups';
      } else {
        redirection.page = PROFILE.APPLE;
        redirection.id = 'certification-company-tabs';
      }
      return redirection;

    case 'buyer_icon':
      redirection.page = `${PEOPLE_ROUTE}`;
      redirection.id = 'certification-buyer-groups';
      return redirection;

    case 'open_company_profile':
      redirection.page = `${COMPANY_OVERVIEW_ROUTE}${companyId}`;
      redirection.id = 'certification-company-tabs';
      return redirection;

    case 'company_profile_execute_prompt_2':
      redirection.page = `${COMPANY_OVERVIEW_ROUTE}${companyId}`;
      redirection.id = 'certification-company-tabs';
      return redirection;

    case 'company_profile_execute_prompt_3':
      redirection.page = `${COMPANY_OVERVIEW_ROUTE}${companyId}`;
      redirection.id = 'certification-company-tabs';
      return redirection;

    case 'company_profile_execute_prompt_4':
      redirection.page = `${COMPANY_OVERVIEW_ROUTE}${companyId}`;
      redirection.id = 'certification-company-tabs';
      return redirection;

    case 'company_profile_sap':
      redirection.page = `${COMPANY_OVERVIEW_ROUTE}154`;
      redirection.id = 'certification-company-tabs';
      return redirection;

    case 'open_buyer_group':
      if (groupId > 0) {
        redirection.page = `${GROUP_DETAIL_ROUTE}${groupId}`;
        redirection.id = 'certification-chatbotv2-btn';
      } else {
        redirection.page = PROFILE.APPLE;
        redirection.id = 'certification-company-tabs';
      }
      return redirection;

    case 'buyer_group_execute_prompt_2':
      if (groupId > 0) {
        redirection.page = `${GROUP_DETAIL_ROUTE}${groupId}`;
        redirection.id = 'certification-chatbotv2-btn';
      } else {
        redirection.page = PROFILE.APPLE;
        redirection.id = 'certification-company-tabs';
      }
      return redirection;

    case 'buyer_group_execute_prompt_3':
      if (groupId > 0) {
        redirection.page = `${GROUP_DETAIL_ROUTE}${groupId}`;
        redirection.id = 'certification-chatbotv2-btn';
      } else {
        redirection.page = PROFILE.APPLE;
        redirection.id = 'certification-company-tabs';
      }
      return redirection;

    case 'open_person_profile':
      redirection.page = `${PERSONALITY_INSIGHTS_ROUTE}${personId}&personType=EXECUTIVE`;
      redirection.id = 'certification-person-header';
      return redirection;

    case 'person_profile_execute_prompt_2':
      redirection.page = `${PERSONALITY_INSIGHTS_ROUTE}${personId}&personType=EXECUTIVE`;
      redirection.id = 'certification-person-header';
      return redirection;

    case 'document_hub_panel':
      redirection.page = DOCUMENTS_ROUTE;
      redirection.id = 'certification-doc-hub';
      return redirection;

    default:
      return redirection;
  }
};

export const callFlurryForCertification = (
  certificationData: CertificationData,
  type: string,
  source: string,
) => {
  const index = { module: -1, task: -1, event: -1 };
  const { data } = certificationData;
  if (certificationData.percentage !== 100) {
    for (let i = 0; i < data.length; i += 1) {
      if (!data[i].completed) {
        for (let j = 0; j < data[i].dataList.length; j += 1) {
          if (!data[i].dataList[j].completed) {
            for (let k = 0; k < data[i].dataList[j].events.length; k += 1) {
              if (
                data[i].dataList[j].events[k].type === type
                && data[i].dataList[j].events[k].status === 0
                && data[i].dataList[j].source === source
              ) {
                index.module = i;
                index.task = j;
                index.event = k;
                return index;
              }
            }
          }
        }
      }
    }
  }
  return index;
};

export const scrollHandlerToggleClass = (
  element: string,
  className: string,
  depend?: boolean,
  documentScroll?: boolean,
) => {
  const header: HTMLElement | null = document.querySelector(element);
  const sticky: number | null = header && header.offsetTop;
  window.addEventListener('scroll', () => {
    if (depend && sticky !== null) {
      if (window.pageYOffset > sticky) {
        return header && header.classList.add(className);
      }
    }
    if (window.pageYOffset > 80) {
      return header && header.classList.add(className);
    }
    return header && header.classList.remove(className);
  });
  if (documentScroll) {
    header
      && header.addEventListener('scroll', () => {
        if (sticky && sticky > 80) {
          return header && header.classList.add(className);
        }
        return header && header.classList.remove(className);
      });
  }
};

enum MobileOS {
  Android = 'android',
  iOS = 'ios',
  Unknown = 'unknown',
  WindowsPhone = 'Windows Phone',
}

export const getMobileOS = (): MobileOS => {
  const userAgent: string = navigator.userAgent || navigator.vendor;

  if (IsMobile()) {
    // Windows Phone must come first because its UA also contains 'Android'
    if (/windows phone/i.test(userAgent)) return MobileOS.WindowsPhone;
    if (/android/i.test(userAgent)) return MobileOS.Android;

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent)) return MobileOS.iOS;
  }
  return MobileOS.Unknown;
};

export const replaceURLWithoutReload = (param: string, value: string) => {
  const url = new URL(window.location.toString());
  url.searchParams.set(param, value);
  window.history.replaceState(null, '', url.toString());
};

export const toLowerCaseWithoutSpace = (str: string) => str.toLowerCase().trim().replace(/ /g, '');

// person mention functions start
export const getCaretCoordinates = (): { x: number; y: number } => {
  let x = 0;
  let y = 0;
  const isSupported = typeof window.getSelection !== 'undefined';
  if (isSupported) {
    const selection = window.getSelection();
    if (selection?.rangeCount !== 0) {
      const range = selection?.getRangeAt(0).cloneRange();
      range?.collapse(true);
      const rect = range?.getClientRects()[0];
      if (rect) {
        x = rect.left;
        y = rect.top;
      }
    }
  }
  return { x, y };
};

export const getCaretIndex = (element: Node) => {
  const sel = window.getSelection && window.getSelection();
  if (sel && sel.rangeCount > 0) {
    const range = window?.getSelection()?.getRangeAt(0);
    const preCaretRange = range?.cloneRange();
    const tmp = document.createElement('div');

    preCaretRange?.selectNodeContents(element);
    preCaretRange?.setEnd(
      range?.endContainer as Node,
      range?.endOffset as number,
    );
    tmp.appendChild(preCaretRange?.cloneContents() as Node);
    const caretPosition = tmp.innerHTML.length;
    return caretPosition;
  }
  return 0;
};

export const getCaretIndexNew = (element: Node) => {
  if (window.getSelection) {
    const sel = window.getSelection();
    if (sel && sel.rangeCount > 0) {
      const range = sel.getRangeAt(0);
      const preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(element);
      preCaretRange.setEnd(range.endContainer, range.endOffset);
      const tmp = document.createElement('div');
      tmp.appendChild(preCaretRange.cloneContents());
      let caretPosition;
      if (tmp.childNodes.length > 0) {
        const lastChild = tmp.childNodes[tmp.childNodes.length - 1];
        if (lastChild.nodeType === Node.TEXT_NODE) {
          caretPosition = lastChild?.textContent?.length;
        }
      }
      return caretPosition ?? 0;
    }
  }
  return 0;
};

export function convertFormattedTextToPlain(text: string) {
  const regex = /[\p{Mn}\p{Me}\p{Cf}\p{M}]/u; // regex to match Unicode combining characters
  const normalized = text.normalize('NFKD'); // normalize text to decompose combined characters
  return normalized.replace(regex, ''); // remove all combining characters
}

export const insertTextInContentEditable = (
  node: HTMLElement,
  textToInsert: string,
) => {
  const selection = window.getSelection();
  if (selection) {
    const range = selection.getRangeAt(0);
    range.deleteContents();
    const textNode = document.createTextNode(textToInsert);
    range.insertNode(textNode);
    // Move the caret to the end of the inserted text
    range.setStartAfter(textNode);
    range.setEndAfter(textNode);
    selection.removeAllRanges();
    selection.addRange(range);
    // Dispatch input event so React updates the state
    const inputEvent = new InputEvent('input', {
      inputType: 'insertText',
      data: textToInsert,
      bubbles: true,
    });
    // Try dispatching the event with the 'new' way first
    if (typeof node.dispatchEvent === 'function') {
      node.dispatchEvent(inputEvent);
    } else {
      // For older browsers, try using fireEvent from the DOM API
      const event = document.createEvent('HTMLEvents');
      event.initEvent('input', true, true);
      node.dispatchEvent(event);
    }
  }
};

export function handlePasteInTextArea(
  event: React.ClipboardEvent<HTMLTextAreaElement>,
) {
  event.preventDefault();
  const { clipboardData } = event;
  const pastedText = clipboardData?.getData('text') ?? '';

  const plainText = convertFormattedTextToPlain(pastedText);
  const textarea = event.target as HTMLTextAreaElement;
  const startPos = textarea.selectionStart ?? 0;
  const endPos = textarea.selectionEnd ?? 0;
  const currentValue = textarea.value;
  const newValue = currentValue.substring(0, startPos)
    + plainText
    + currentValue.substring(endPos);

  textarea.value = newValue;
  textarea.setSelectionRange(
    startPos + plainText.length,
    startPos + plainText.length,
  );
  return newValue;
}

export const extractTextWithIndexes = (html: string, trim = true) => {
  const taggedPersons: PersonMentions[] = [];
  const wrapper = document.createElement('div');
  wrapper.innerHTML = html;
  const nodes = Array.from(wrapper.childNodes);
  let transformedText = '';
  let previousNodeWasAnchor = false;
  for (let i = 0; i < nodes.length; i += 1) {
    const node = nodes[i];
    if (node.nodeType === Node.TEXT_NODE) {
      const textContent = node.textContent || '';
      const encodedText = Array.from(textContent)
        .map((char) => {
          if (char.length > 1 && char.match(/\p{Emoji}/gu)) {
            return `&#${char.codePointAt(0)};`;
          }
          if (char.match(/\p{Extended_Pictographic}/gu)) {
            return `&#${char.codePointAt(0)};`;
          }
          return char;
        })
        .join('');
      transformedText += encodedText;
      previousNodeWasAnchor = false;
    } else if (node.nodeType === Node.ELEMENT_NODE && node.nodeName === 'A') {
      const anchor = node as HTMLAnchorElement;
      const anchorText = anchor.textContent || '';
      const personId = anchor.getAttribute('id') || '';
      const personType = anchor.getAttribute('data-type') || '';
      const anchorStart = transformedText.length;
      transformedText += `${anchorText}`;
      const anchorEnd = transformedText.length;
      const personObj: PersonMentions = {
        params: {
          personId,
          personName: anchorText,
          personType,
        },
        indexes: {
          start: anchorStart,
          end: anchorEnd,
        },
      };
      taggedPersons.push(personObj);
      previousNodeWasAnchor = true;
    } else if (node.nodeType === Node.ELEMENT_NODE && node.nodeName === 'BR') {
      transformedText += '\n';
      previousNodeWasAnchor = false;
    }
  }
  if (trim) {
    transformedText = transformedText.trim();
  }
  return {
    transformedText,
    taggedPersons,
  };
};

const encodingCharacterHandler = (str: string) => str.replace(/&/g, '&').replace(/</g, '<').replace(/>/g, '>');

const decodeEmojis = (str: string): string => {
  const regex = /&([^;]+);/g;
  return str.replace(regex, (match, submatch) => {
    const decoded = new DOMParser().parseFromString(
      `<!doctype html><body>${submatch}`,
      'text/html',
    ).body.textContent;
    return `&${decoded};`;
  });
};

export const insertAnchorTags = (commentNotesObj: EntityComment): string => {
  const { text, entities } = commentNotesObj;
  const modifiedStr = text.replace(/\r/g, '');
  const wrapper = document.createElement('div');
  let index = 0;

  if (entities && entities.personMentions?.length) {
    entities.personMentions.forEach(({ indexes, params }) => {
      const { start, end } = indexes;
      const { personId, personName, personType } = params;

      // Create anchor tag
      const beforeText = encodingCharacterHandler(
        modifiedStr.substring(index, start),
      );
      const beforeTextNode = document.createTextNode(beforeText);
      const anchor = document.createElement('a');
      anchor.id = personId;
      anchor.dataset.type = personType || 'EXECUTIVE';
      anchor.href = `${window.location.origin}${PERSONALITY_INSIGHTS_ROUTE}${personId}&personType=${personType}`;
      anchor.target = '_blank';
      anchor.draggable = false;
      anchor.classList.add('tagged-person');
      if (personId === 'null') {
        anchor.classList.add('disabled');
      }
      anchor.setAttribute('contenteditable', 'false');
      anchor.innerHTML = encodingCharacterHandler(
        modifiedStr.substring(start, end),
      );

      wrapper.appendChild(beforeTextNode);
      wrapper.appendChild(anchor);
      index = end;
    });
  }

  // Append remaining text
  const afterText = encodingCharacterHandler(modifiedStr.substring(index));
  wrapper.appendChild(document.createTextNode(afterText));

  // Encode and return HTML
  const decodedHTML = wrapper.innerHTML;
  const textarea = document.createElement('textarea');
  textarea.innerHTML = decodedHTML;
  const encodedHTML = textarea.value;

  const htmlWithEmojisDecoded = decodeEmojis(
    encodedHTML.replace(/\n/g, '<br>'),
  );
  return htmlWithEmojisDecoded;
};

export const modifyReason = (html: string) => {
  const wrapper = document.createElement('div');
  wrapper.innerHTML = html;
  const divs = wrapper.querySelectorAll('div');
  let extraBRAdded = false;
  let extraBRAddedAlready = false;
  const elementsToRemove: HTMLElement[] = [];
  divs?.forEach((div) => {
    const brTag = document.createElement('br');
    const parent = div?.parentNode;
    if (!extraBRAdded) {
      parent?.insertBefore(brTag, div);
    } else {
      extraBRAdded = false;
    }
    // Remove <br> from <div> with more than one childNode
    if (div?.childNodes.length > 1) {
      const brElement = div?.querySelector('br');
      if (brElement && div.contains(brElement)) {
        // check if brElement is a child of div before removing
        elementsToRemove.push(brElement);
      }
    } else if (
      div?.childNodes.length === 1
      && div?.firstChild?.nodeName === 'BR'
    ) {
      // Add <br> after <div><br></div>, except for the first occurrence
      if (!extraBRAddedAlready) {
        parent?.insertBefore(brTag, div);
        extraBRAddedAlready = true;
      }
      extraBRAdded = true;
    } else {
      extraBRAddedAlready = false;
    }
    while (div?.firstChild) {
      const child = div.firstChild;
      if (child && parent?.contains(child)) {
        parent.insertBefore(child, div);
      } else {
        break;
      }
    }
    if (parent && div.parentNode === parent) {
      elementsToRemove.push(div);
    }
  });
  // Remove elements after iterating to prevent DOM errors
  elementsToRemove.forEach((element) => {
    element.parentNode?.removeChild(element);
  });
  return wrapper.innerHTML;
};

export const setCaretAtTheEndHandler = (node: HTMLElement) => {
  if (node instanceof HTMLElement && node.lastChild) {
    const range = document.createRange();
    const selection = window.getSelection();

    range.selectNodeContents(node.lastChild);
    range.collapse(false);

    if (selection) {
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }
};

export const preventStylingShortcuts = (
  event: React.KeyboardEvent<HTMLDivElement>,
) => {
  // List of all shortcut keys for text styling
  const stylingShortcuts = ['b', 'i', 'u', 'k', '5', ']', '}', '*', '_'];
  // Check if the pressed key is a styling shortcut key
  if (
    (event.ctrlKey || event.metaKey) // Ctrl or Command key
    && stylingShortcuts.includes(event.key.toLowerCase()) // Styling shortcut key
  ) {
    event.preventDefault(); // Prevent default behavior
  }
};

export const createNotesPayloadObj = (obj: AddNoteRequestPayload) => ({
  ...(obj.personId && { personId: obj.personId }),
  ...(obj.companyId && { companyId: obj.companyId }),
  note: obj.note,
  type: obj.type,
  ...(!!obj.taggedPersons.length && {
    meta: { entities: { personMentions: obj.taggedPersons } },
  }),
});

// person mention functions End
export const showCertificationInApp = () => isActive()
  && !isFreeUser()
  && !isGuest()
  && !MinWidthApp()
  && !isSalesforce();

export const removeQueryParam = (param: string) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  urlSearchParams.delete(param);
  const newUrl = urlSearchParams.toString()
    ? `${window.location.pathname}?${urlSearchParams.toString()}`
    : window.location.pathname;
  window.history.replaceState(null, '', newUrl);
};

export const isElement = (element: HTMLElement | null, offset: number) => {
  if (element) {
    const yOffset = offset;
    const yScroll = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: yScroll, behavior: 'smooth' });
  }
};

export const scrollToElement = (
  targetId: string,
  ref: HTMLDivElement | null,
) => {
  const targetElement = document.getElementById(targetId);
  if (targetElement && ref) {
    const container = ref;
    container.scrollTo({
      top: targetElement.offsetTop - 48,
      behavior: 'smooth',
    });
  }
};

export const scrollToTarget = (
  targetClass: string,
  ref: HTMLDivElement | null,
) => {
  const container = ref;
  if (container) {
    const targetElements = container.querySelectorAll(targetClass);
    if (targetElements.length > 0) {
      const lastTargetElement = targetElements[
        targetElements.length - 1
      ] as HTMLElement;
      container.scrollTo({
        top: lastTargetElement.offsetTop - 48,
        behavior: 'smooth',
      });
    }
  }
};

export const handleException = () => {
  notification.open({
    top: 80,
    className: classNames({ errorNotification: true }, { centerMessage: true }),
    message: 'Something went wrong. Please try again',
  });
};

export const removeHtmlTags = (input: string): string => {
  const doc = new DOMParser().parseFromString(input, 'text/html');
  return doc.body.textContent || '';
};

export const getFileSize = (bytes: number): string => {
  if (bytes >= 1048576) {
    return `${(bytes / 1048576).toFixed(1)} MB`;
  }
  return `${(bytes / 1024).toFixed(0)} KB`;
};

export const extractSpanTagFromString = (input: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(input, 'text/html');
  return doc.querySelector('span');
};

export const extractSpanIdsFromHTML = (htmlString: string) => {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = htmlString;
  const spanElements = tempDiv.querySelectorAll('span');
  const results: number[] = [];
  spanElements.forEach((span) => {
    const id = parseInt(span.getAttribute('id')?.replace('%', '') ?? '', 10);
    const key = span.getAttribute('key') ?? '';
    if (key?.length) {
      results.push(id);
    }
  });
  return results;
};

export const extractSpanTagsFromHTML = (htmlString: string) => {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = htmlString;
  const spanElements = tempDiv.querySelectorAll('span');
  const results: TagItem[] = [];
  spanElements.forEach((span) => {
    const id = parseInt(span.getAttribute('id') ?? '', 10);
    const key = span.getAttribute('key') ?? '';
    const name = span.innerText.replace(/@/g, '');
    const image = span.dataset.image ?? '';
    const color = span.dataset.color ?? '';
    if (key?.length) {
      results.push({
        type: key as 'person' | 'company' | 'group',
        id,
        name,
        image,
        color,
      });
    }
  });
  return results;
};

export const imageSource = (type: string) => {
  switch (type) {
    case 'pdf':
      return pdfFileIcon;
    case 'doc':
      return wordFileIcon;
    case 'docx':
      return wordFileIcon;
    case 'txt':
      return txtFileIcon;
    case 'pptx':
      return pptFileIcon;
    case 'ppt':
      return pptFileIcon;
    default:
      return alertArticlePlaceholder;
  }
};

export const getResultArray = (data: string, type: string) => {
  const array: string[] = [];
  if (data) {
    const ids = data.split(',');
    if (ids.length) {
      for (const id of ids) {
        array.push(type);
      }
    }
  }
  return array;
};

const replaceFirstArticleWithImageArticle = (chunk: Article[]) => {
  const arrTemp: Article[] = [];
  let imageFound = false;
  // eslint-disable-next-line array-callback-return
  chunk.map((item) => {
    if (item?.image && !imageFound) {
      arrTemp.unshift(item);
      imageFound = true;
    } else {
      arrTemp.push(item);
    }
  });

  return arrTemp;
};
const replaceLasttArticleWithImageArticle = (chunk: Article[]) => {
  const arrTemp: Article[] = [];
  // eslint-disable-next-line array-callback-return
  chunk.map((item) => {
    if (item?.image) {
      arrTemp.push(item);
    } else {
      arrTemp.unshift(item);
    }
  });

  return arrTemp;
};

export const splitArrayChunks = (arr: Article[], chunkSize: number, isMobile?: boolean, isListView?: boolean) => {
  const chunks = [];
  const sortedArray: Article[][] = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    chunks.push(chunk);
  }

  if (isListView) {
    return chunks;
  }

  // eslint-disable-next-line array-callback-return
  chunks.filter((chunk, index) => {
    if (index % 2 === 0 && !(chunk[0]?.image)) {
      sortedArray.push(replaceFirstArticleWithImageArticle(chunk));
    } else if (!isMobile && !(index % 2 === 0) && !(chunk[chunk.length - 1]?.image)) {
      sortedArray.push(replaceLasttArticleWithImageArticle(chunk));
    } else if (isMobile && !(index % 2 === 0) && !(chunk[0]?.image)) {
      sortedArray.push(replaceFirstArticleWithImageArticle(chunk));
    } else {
      sortedArray.push(chunk);
    }
  });

  return sortedArray;
};

const upperCaseReg = /(?=.*?[A-Z])/;
const lowerCaseReg = /(?=.*?[a-z])/;
const oneNum = /(?=.*?[0-9])/;
const specialChar = /(?=.*?[#?!@$%^&*-])/;

export const validatePassword = (password: string) => {
  const validate = {
    long: false,
    special: false,
    number: false,
    uppercase: false,
    lowercase: false,
  };
  if (password.length >= 12) validate.long = true;
  if (upperCaseReg.test(password)) validate.uppercase = true;
  if (lowerCaseReg.test(password)) validate.lowercase = true;
  if (oneNum.test(password)) validate.number = true;
  if (specialChar.test(password)) validate.special = true;

  return {
    validate,
    isValid: _.isEmpty(validate),
  };
};

export const getLangCookie = () => {
  const cookieValue = Cookies.get('xiqtrans');
  return cookieValue !== undefined ? cookieValue : 'en';
};

const baseURL = process.env.REACT_APP_BASE_URL;

export const Options: DropdownOptions = {
  pageLanguage: 'en',
  siteName: '',
  chunkSize: 10,
  attributionImageUrl: undefined,
  logoImageUrl: undefined,
  preferredSupportedLanguages: [],
  intersectionThreshold: 0.0,
  ignoreIntersection: false,
  ignoreClasses: [],
  ignoreSelectors: [],
  verboseOutput: false,
  updateDocumentLanguageAttribute: false,
  includedAttributes: ['title', 'placeholder', 'alt'],
  endpoints: {
    supportedLanguages: `${baseURL}${API.SUPPORTED_LANGUAGE}`,
    updateTranslation: '',
    translate: `${baseURL}${API.TRANSLATION_HTML}`,
  },
};

export const getPageName = () => {
  const page = window.location.href;
  let pageName = '';
  if (page.includes('/people')) {
    pageName = 'PeopleDashboard';
  } else if (page.includes('/companies')) {
    pageName = 'CompanyDashbaord';
  } else if (page.includes('/feed')) {
    pageName = 'Feed';
  } else if (page.includes('/person')) {
    pageName = 'PersonProfile';
  } else if (page.includes('/company')) {
    pageName = 'CompanyProfile';
  } else if (page.includes('/groupdetails')) {
    pageName = 'BuyerGroup';
  } else if (page.includes('/documents')) {
    pageName = 'DocumentHub';
  } else if (page.includes('/sri')) {
    pageName = 'SalesSigma';
  } else if (page.includes('/industries')) {
    pageName = 'IndustryDashboard';
  } else if (page.includes('/industry')) {
    pageName = 'IndustryProfile';
  }
  return pageName;
};

export const getSortingLabel = (key: string) => {
  switch (key) {
    case '1':
      return 'companyName:asc';
    case '2':
      return 'companyName:desc';
    case '3':
      return 'dateFollowed:desc';
    case '4':
      return 'dateFollowed:asc';
    case '5':
      return 'revenue:asc';
    case '6':
      return 'revenue:desc';
    case '7':
      return 'latestActivity:asc';
    case '8':
      return 'latestActivity:desc';
    default:
      return 'dateFollowed:desc';
  }
};

export const getSortingDefaultKey = (sortingType: string) => {
  switch (sortingType) {
    case 'companyName:asc':
      return '1';
    case 'companyName:desc':
      return '2';
    case 'dateFollowed:desc':
      return '3';
    case 'dateFollowed:asc':
      return '4';
    case 'revenue:asc':
      return '5';
    case 'revenue:desc':
      return '6';
    default:
      return '0';
  }
};

export const getFlurrySortingType = (type: string) => {
  switch (type) {
    case 'companyName:asc':
      return 'click_sortByNameAsc_companyDashboard';
    case 'companyName:desc':
      return 'click_sortByNameDesc_companyDashboard';
    case 'dateFollowed:asc':
      return 'click_sortByDateFollowedAsc_companyDashboard';
    case 'dateFollowed:desc':
      return 'click_sortByDateFollowedDesc_companyDashboard';
    case 'revenue:asc':
      return 'click_sortByRevenueAsc_companyDashboard';
    default:
      return 'click_sortByRevenueDesc_companyDashboard';
  }
};

export const isXiqUser = () => {
  const email = getLocalStorageValue('email');
  const emailDomain = email?.split('@')[1].toLowerCase();

  if (emailDomain === 'xiqinc.com') {
    return true;
  }
  return false;
};

export const formatNumberWithCommas = (value :number) => new Intl.NumberFormat('en-US').format(value);
export const isNutanixUser = () => {
  const email = getLocalStorageValue('email');
  const emailDomain = email?.split('@')[1].toLowerCase();

  if (emailDomain === 'nutanix.com') {
    return true;
  }
  return false;
};

export const replaceTags = (input: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(input, 'text/html');
  const spanElements = doc.querySelectorAll('span');
  let updatedInput = input.replace(/=""/g, '');

  spanElements?.forEach((spanElement) => {
    const keyAttribute = spanElement.getAttribute('key');
    if (keyAttribute) {
      let tagSpanElement = spanElement.outerHTML;
      tagSpanElement = tagSpanElement.replace(/=""/g, '');

      if (keyAttribute === 'person') {
        updatedInput = updatedInput.replace(
          tagSpanElement,
          '<span class="prompt-input-style personAppend" contenteditable="false">@Person name</span>',
        );
      } else if (keyAttribute === 'company') {
        updatedInput = updatedInput.replace(
          tagSpanElement,
          '<span class="prompt-input-style companyAppend" contenteditable="false">@Company name</span>',
        );
      } else if (keyAttribute === 'group') {
        updatedInput = updatedInput.replace(
          tagSpanElement,
          '<span class="prompt-input-style groupAppend" contenteditable="false">@Buyergroup</span>',
        );
      } else if (keyAttribute === 'document') {
        updatedInput = updatedInput.replace(
          tagSpanElement,
          '<span class="prompt-input-style documentAppend" contenteditable="false">@document name</span>',
        );
      }
    }
  });

  return updatedInput;
};
export const stringToCamelCase = (str: string) => str
  .toLowerCase()
  .split(' ')
  .map((word, index) => (index === 0
    ? word
    : word.charAt(0).toUpperCase() + word.slice(1)))
  .join('');

export const camelCaseToNormal = (text: string): string => text
  .replace(/([A-Z])/g, ' $1')
  .replace(/^./, (str) => str.toUpperCase())
  .trim();
